/* src/components/StripeAPI2/RegistrationAndSubscriptionForm.css */
.form-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f8f8;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
  
.form-title {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
}
  
.styled-form {
    display: flex;
    flex-direction: column;
}
  
.form-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}
  
.form-group {
    flex: 1;
    margin-right: 15px;
}
  
.form-group:last-child {
    margin-right: 0;
}
  
.label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
}
  
.input,
.select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
}
  
.section-title {
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #333;
}
  
.card-element-container {
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 4px;
    background-color: white;
}
  
.error-message {
    color: #d32f2f;
    font-size: 14px;
    margin-top: 5px;
}
  
.submit-button {
    background-color: #4CAF50;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
}
  
.submit-button:hover {
    background-color: #45a049;
}
  
.submit-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}
