.secondary-header {
    background-color: #005599;
    padding: 8px 20px;
    border-bottom: 2px solid #003366;
    position: relative;
    z-index: 1000;
}

.secondary-header ul {
    display: flex;
    gap: 10px;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.secondary-header ul li {
    position: relative;
    cursor: pointer;
    color: white;
}

.secondary-header ul li a {
    color: white;
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 4px 8px;
    border-radius: 5px;
    transition: all 0.3s ease;
}

.secondary-header ul li:hover > a {
    background-color: rgba(27, 16, 82, 0.1);
}

.secondary-header ul li.primary-highlight > a {
    font-weight: bold;
    background: linear-gradient(135deg, #4169E1, #8A2BE2);
    color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.secondary-header ul li.primary-highlight:hover > a {
    background: linear-gradient(135deg, #4169E1, #9370DB);
}

.secondary-header .dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #003366;
    border: 1px solid #aaa;
    border-radius: 0 0 5px 5px;
    display: none;
    flex-direction: column;
    padding: 5px 0;
    width: 300px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.secondary-header .dropdown.narrow-dropdown {
    width: 200px; /* Narrower width for stocks, crypto, and forex dropdowns */
}

.secondary-header .dropdown .dropdown-subtitle {
    padding: 8px 12px;
    font-weight: bold;
    text-decoration: underline;
    color: white;
    background-color: #002244;
    text-align: center;
}

.secondary-header li:hover .dropdown {
    display: flex;
}

.secondary-header .dropdown a {
    padding: 6px 12px;
    color: white;
    text-decoration: none;
    white-space: nowrap;
}

.secondary-header .dropdown a:hover {
    background-color: #004477;
}

.secondary-header li:has(.dropdown) > a::after {
    content: '\25BC';
    margin-left: 5px;
    font-size: 0.8em;
}

.secondary-header i, .secondary-header svg {
    margin-right: 3px;
}

.secondary-header ul li a:active {
    transform: translateY(1px);
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
}

.secondary-header .dropdown a i.fa-star {
    color: #FFD700;
}

.calendar-link-group {
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
}

.calendar-link-group .table-link {
    flex: 1;
    padding: 6px 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
}

.mosaic-link {
    flex: 0 0 32px;
    text-align: center;
    border-left: 1px solid #4a6c8f;
    border-right: 1px solid #4a6c8f;
    padding: 6px 0;
    margin-right: 1px;
}

.mosaic-link i {
    color: #8A2BE2;
}

.mosaic-link:hover {
    background-color: #004477;
}

.mosaic-link:hover i {
    color: #fff;
}

.calendar-subheader {
    display: flex;
    justify-content: space-between;
    padding: 5px 8px;
    background-color: #002244;
    border-bottom: 1px solid #4a6c8f;
}

.calendar-subheader span {
    flex: 1;
    text-align: center;
    font-weight: bold;
    color: #ccc;
}

.calendar-subheader span:last-child {
    flex: 0 0 32px;
}

@media (max-width: 768px) {
    .secondary-header {
        padding: 8px 15px;
    }

    .secondary-header ul {
        flex-direction: column;
        gap: 8px;
    }

    .secondary-header ul li {
        width: 100%;
    }

    .secondary-header ul li a {
        justify-content: flex-start;
        padding: 5px 10px;
    }

    .secondary-header ul li.primary-highlight > a {
        margin-bottom: 5px;
        display: inline-flex;
        width: auto;
    }

    .secondary-header .dropdown,
    .secondary-header .dropdown.narrow-dropdown {
        position: static;
        display: none;
        background-color: rgba(65, 105, 225, 0.1);
        border: none;
        box-shadow: none;
        width: 100%;
    }

    .secondary-header li:hover .dropdown {
        display: flex;
    }

    .secondary-header ul li a span {
        white-space: normal;
        overflow-wrap: break-word;
    }

    .secondary-header i, .secondary-header svg {
        min-width: 20px;
        text-align: center;
    }

    .secondary-header .dropdown a {
        width: 100%;
        box-sizing: border-box;
    }
}