/* Header.css */

/* Existing styles */
.site-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 15px 30px;
  background-color: #003366;
  color: white;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  overflow: hidden;
}

.logo-img {
  height: 40px;
  width: auto;
  filter: brightness(0) invert(1);
}

.logo {
  font-weight: bold;
  font-size: 24px;
  margin-right: 20px;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}

.main-menu,
.secondary-menu {
  display: flex;
  gap: 15px;
}

.main-menu {
  margin-left: auto; /* Push main menu items to the right */
}

.main-menu li,
.secondary-menu li {
  list-style-type: none;
}

.main-menu a,
.secondary-menu a {
  text-decoration: none;
  color: white;
}

.search-container {
  display: flex;
  align-items: center;
  margin-right: 30px;
  border: 1px solid #aaa;
  padding: 5px;
  border-radius: 20px;
  background-color: white;
  flex-grow: 1;
  max-width: 300px;
  box-sizing: border-box;
}

.search-box {
  border: none;
  outline: none;
  padding: 10px;
  flex-grow: 1;
  border-radius: 20px 0 0 20px;
  color: #333;
  width: 100%;
}

.search-icon {
  background-color: #003366;
  padding: 10px 15px;
  border-radius: 0 20px 20px 0;
  color: white;
  cursor: pointer;
  border: none;
}

/* Subscribe button styling */
.subscribe-button {
  background-color: #28a745; /* Green color for Subscribe */
  color: white;
  border: none;
  padding: 10px 15px;
  text-align: center;
  font-size: 16px;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-decoration: none;
  display: inline-block;
}

.subscribe-button:hover {
  background-color: #218838;
}

/* Logout button styling */
.logout-button {
  background-color: #dc3545; /* Red color for Logout */
  color: white;
  border: none;
  padding: 10px 15px;
  text-align: center;
  font-size: 16px;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.logout-button:hover {
  background-color: #c82333;
}

/* Profile button styling */
.profile-button {
  background-color: #007bff; /* Blue color for Profile */
  color: white;
  border: none;
  padding: 10px 15px;
  text-align: center;
  font-size: 16px;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-decoration: none;
  display: inline-block;
}

.profile-button:hover {
  background-color: #0056b3;
}

/* Get Started button */
.get-started-btn {
  background-color: #4CAF50; /* Green color */
  border: none;
  color: white;
  padding: 10px 15px;
  text-align: center;
  font-size: 16px;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.get-started-btn:hover {
  background-color: #45a049;
}

.auth-buttons {
  display: flex;
  align-items: center;
  gap: 15px;
}

.auth-buttons a,
.auth-buttons button {
  text-decoration: none;
  color: white;
  font-size: 16px;
  padding: 10px 15px;
  border-radius: 12px;
  display: inline-block;
}

.auth-buttons a:hover,
.auth-buttons button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Mobile styles */
.menu-toggle {
  display: none;
}

@media (max-width: 768px) {
  .site-header {
    padding: 15px;
  }

  .header-content {
    display: none;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }

  .header-content.open {
    display: flex;
  }

  .logo {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .search-container {
    order: 1;
    margin-right: 0;
    margin-bottom: 10px;
    max-width: 100%;
    width: 100%;
  }

  .menu-toggle {
    display: block;
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
  }

  .menu-toggle span {
    display: block;
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 5px 0;
  }

  .main-menu,
  .secondary-menu {
    flex-direction: column;
    width: 100%;
    gap: 10px;
    margin-left: 0;
    padding-left: 0;
  }

  .auth-buttons {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 10px;
  }

  .subscribe-button,
  .logout-button,
  .profile-button,
  .get-started-btn {
    width: 100%;
    margin: 5px 0;
  }
}

/* Ensure body does not overflow horizontally */
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
